import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Hammer from '@egjs/hammerjs';
import { HomeCardSizes, HomeCardZIndexes } from '../interfaces';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() data: any;
  @Input() page: any;
  @Input() id: any;
  @ViewChild('swipableHome')
  swipableHome: ElementRef | undefined;

  ngAfterViewInit() {
    if ( this.swipableHome ) {
      let element: HTMLElement = this.swipableHome.nativeElement as HTMLElement;
      let mc = new Hammer.Manager(element, {
        recognizers: [
          [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
        ]
      });
      mc.on("swiperight", () => {
        this.onSwipe(-1)
      });
      mc.on("swipeleft", () => {
        this.onSwipe(+1)
      });
    }
  }

  updating = false
  selectedIndex = 1

  positions = ['top-5', 'left-20', 'top-[12rem] left-72']
  back_position = ['top-5', 'left-64', 'top-[12rem] left-72']

  getCardClass(index: number): string {
    if (index == this.selectedIndex) {
      return this.positions[index] + ' ' + HomeCardSizes.big + ' ' + HomeCardZIndexes.adove
    }
    else {
      return this.back_position[index] + ' ' + HomeCardSizes.small + ' ' + HomeCardZIndexes.below
    }
  }

  getSlidePosition(): string {
    if (this.selectedIndex == 0) {
      return 'left-0'
    } else {
      return 'left-' + (this.selectedIndex) + '/3'
    }
  }

  switchPhoto(index: number) {
    if (index != this.selectedIndex) {
      this.selectedIndex = index
      this.updateBackground()
    }
  }

  onSwipe(value: number) {
    if (this.selectedIndex + value < 0) { this.selectedIndex = 2 }
    else if (this.selectedIndex + value > 2) { this.selectedIndex = 0 }
    else { this.selectedIndex += value }
    this.updateBackground()
  }

  updateBackground() {
    this.updating = true
    setTimeout(() => {
      this.updating = false;
    }, 800);
  }

}
