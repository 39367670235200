import { Component, OnInit, Input } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss']
})
export class SeoComponent implements OnInit {
  @Input() data: any;
  @Input() page: any;
  @Input() id: any;


  constructor(private meta: Meta) {}

  ngOnInit() {    
    this.meta.addTag({ name: 'title', content: this.data.title });
    this.meta.addTag({ name: 'og:title', content: this.data.title });
    this.meta.addTag({ name: 'description', content: this.data.description });
    this.meta.addTag({ name: 'og:description', content: this.data.description });
    this.meta.addTag({ name: 'og:image', content: this.data.image.url });
    this.meta.addTag({ name: 'og:image:width', content: this.data.image.width });
    this.meta.addTag({ name: 'og:image:height', content: this.data.image.height });
    this.meta.addTag({ name: 'og:image:type', content: this.data.image.mime });
  }
  
}
