<div id={{id}} class="px-5 w-full flex justify-center mb-12 md:mb-28">
  <div class="w-full max-w-[1360px] min-h-[520px] rounded-[30px] bg-[#CFC5E7] bg-no-repeat bg-contain bg-right-top md:bg-right-bottom px-8 py-8 md:px-[112px] md:py-[106px]" style="background-image: url('{{data.image.url}}');">
    <div class="flex mb-[275px] md:mb-8">
      <div *ngFor="let image of data.images" class=" first:ml-0 -ml-[30px]">
        <img class="w-[60px] h-[60px]" src="{{image.image.url}}" />
      </div>
    </div>
    <h2 class="text-mobileH2 md:text-desktopH2 font-morganite uppercase max-w-[575px] mb-8">
      {{data.title}}
    </h2>
    <a href="{{data.button.button_external_link}}">
      <button class="primary" [attr.data-hover]="data.button.button_text">
        <div>
          {{data.button.button_text}}
        </div>
      </button>
    </a>
  </div>
</div>
