<div id={{id}} class="flex justify-center bg-grey-dark w-full pb-[60px] md:pb-[120px]">
  <div class="w-full max-w-[1098px]">
    <div class="flex flex-col justify-center mb-5 md:mb-9">
      <div class="text-mobileOverline md:text-desktopOverline font-montrealMedium text-grey uppercase mb-5 text-center">
        {{data.text}}
      </div>
      <h2 class="text-mobileH2 md:text-desktopH2 font-morganite text-white text-center uppercase">
        {{data.title}}
      </h2>
    </div>
    <div class="flex md:flex-row flex-col gap-5 justify-center w-[50%] md:w-full mx-auto md:mx-0">
      <a *ngFor="let button of data.button; index as i" href="{{button.button_external_link}}">
        <button *ngIf="button.logo" class="primary text-black bg-white border border-white w-full md:w-auto md:mb-32 flex gap-2.5 justify-center" [attr.data-hover]="button.button_text">
          <div class="flex gap-2">
            {{button.button_text}}
            <img src="{{button.logo.url}}" class="ml-2.5">
          </div>
        </button>
        <button *ngIf="!button.logo" class="primary text-black bg-white border border-white w-full md:w-auto md:mb-32 flex gap-2.5 justify-center" [attr.data-hover]="button.button_text">
          <div>{{button.button_text}}</div>
        </button>
      </a>
    </div>
  </div>
</div>
