import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getPage(page: string) {
    return this.http.get<any>(`${environment.apiUrl}/${page}`);
  }

  postSample(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}/postsample`, payload);
  }

  postEmail(payload: any) {
    return this.http.post<any>(`${environment.apiUrl}/postemail`, payload);
  }

  registerContact(payload: any) {
    return this.http.post<any>(`/api/register-contact`, payload);
  }
}