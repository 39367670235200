<div id="{{id}}" #scrollableContainer class="px-5 lg:px-0 w-full flex justify-center pb-12 md:mb-[120px]">
  <div class="w-full max-w-[1098px]">
    <div class="text-mobileOverline md:text-desktopOverline font-montrealMedium pb-4 uppercase">{{data.text}}</div>
    <div class="sm:flex justify-between mb-8 md:mb-14">
      <h2 class="text-mobileH2 md:text-desktopH2 font-morganite uppercase">{{data.title}}</h2>
      <div>
        <a href="{{data.button.button_external_link}}" class="no-underline">
          <button [attr.data-hover]="data.button.button_text" class="primary">
            <div>            
                {{data.button.button_text}}
            </div>
          </button>
        </a>
      </div>
    </div>
    <div #scrollableCards class="skinvaders-slider flex flex-col md:flex-row gap-5 scrollbar-hide fill-margin" onclick="sliderController()">
      <div *ngFor="let card of data.images" class="skinvaders-slider-item border-black border rounded-[20px] bg-purple-secondary p-4">
        <img class="mb-10 rounded-[15px]" src="{{card.image.url}}" />
        <div class="flex justify-between items-end md:items-start">
          <div class="flex flex-col">
            <h5 class="text-mobileH5 md:text-desktopH5 font-montrealMedium mb-4">{{card.title}}</h5>
            <div class="text-mobileParagrapheXL md:text-desktopParagrapheXL font-montreal">
              {{card.description}}
            </div>
          </div>
          <div class=" text-mobileHighlight md:text-desktopHighlight font-morganite md:min-w-[95px] text-right">
            0{{card.number}}
          </div>
        </div>
      </div>
      <a href="{{data.button.button_external_link}}" class="no-underline">
        <button [attr.data-hover]="data.button.button_text" class="primary sm:hidden">
          <div>          
              {{data.button.button_text}}        
          </div>
        </button>
      </a>
    </div>
  </div>
</div>
