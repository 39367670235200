<div id={{id}} class="w-full flex justify-center bg-grey-dark text-center pb-14 md:pb-[120px]">
  <div class="w-full max-w-[1098px] px-5 md:px-0">
    <div class="flex flex-col justify-center mb-9 text-center">
      <div class="text-mobileOverline md:text-desktopOverline font-montrealMedium text-grey uppercase mb-5">
        {{data.text}}
      </div>
      <h2 class="text-mobileH2 md:text-desktopH2 font-morganite text-white uppercase">
        {{data.title}}
      </h2>
    </div>
    <div class="lg:grid lg:grid-cols-3">
      <div *ngFor="let card of data.element" class="">
        <div class="bg-cover bg-no-repeat bg-center flex justify-center items-center min-h-[237px] min-w-[277px]" style="background-image: url('assets/img/grid_background.svg');">
          <img src="{{card.image.url}}">
        </div>
        <h6 class="mt-9 pb-2 text-desktopH6 font-montrealMedium text-white text-center">
          {{card.title}}
        </h6>
        <div class="pb-5 md:pb-0 text-grey text-desktopParagraphe font-montrealMedium text-center">
          {{card.description}}
        </div>
      </div>
    </div>
  </div>
</div>
