<div id={{id}} class="flex justify-center bg-grey-dark w-full pb-[120px]">
  <div class="w-full max-w-[1098px] px-5 md:px-0">
    <div class="flex flex-col justify-center mb-9 text-center md:text-left">
      <div class="text-desktopOverline font-montrealMedium text-grey uppercase mb-5">
        {{data.text}}
      </div>
      <h2 class="text-desktopH2 font-morganite text-white uppercase">
        {{data.title}}
      </h2>
    </div>
    <div class="md:grid md:grid-cols-2 gap-5 w-full overflow-x-scroll md:overflow-x-auto flex group">
      <div *ngFor="let card of data.element" class="relative group group-hover:opacity-40 hover:!opacity-100 transition-all duration-150 hidden md:block bg-no-repeat bg-[initial] bg-right-top rounded-[18px] bg-[#24282B] p-5 shrink-0 basis-[301px] grow-0 md:w-auto">   
        <div class="absolute right-0 top-0 w-44 h-44 bg-no-repeat bg-right-top group-hover:saturate-200 transition-all duration-300" style="background-image: url({{card.background.url}});"></div>       
          
        <div class="flex gap-3 py-4 px-5 rounded-[10px] w-fit mb-2" style="color: {{card.card_color}}; background-color: {{card.card_color}}1a;">
          <img src="{{card.top_logo.url}}">
          <span>
            {{card.top_text}}
          </span>
        </div>
        <div class="md:p-[30px]">
          <div class="max-w-[80%] text-white text-mobileH3 md:text-desktopH3 font-montrealMedium">
            {{card.title}}
          </div>
          <div class="flex flex-col gap-8 mt-12">
            <div *ngFor="let list of card.list" class="text-white text-desktopParagrapheXL font-montrealBook whitespace-pre-wrap pb-8 border-b border-grey flex last:border-b-0">
              <div class="h-[25.5px] !w-[25.5px] basis-[25.5] grow-0 shrink-0 bg-[#ffffff1a] rounded-full flex justify-center items-center">
                <img src="assets/img/check.svg">
              </div>
              <div class="ml-4">
                {{list.title}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let card of data.element" class="md:hidden bg-no-repeat bg-[initial] bg-right-top rounded-[18px] bg-[#24282B] p-5 shrink-0 basis-[301px] grow-0 md:w-auto">
        <div class="flex md:hidden gap-3 py-4 px-5 rounded-[10px] w-fit mb-2" style="color: {{card.card_color}}; background-color: {{card.card_color}}1a;">
          <img src="{{card.top_logo.url}}">
          <span>
            {{card.top_text}}
          </span>
        </div>
        <div class="md:p-[30px]">
          <div class="max-w-[80%] text-white text-mobileH3 md:text-desktopH3 font-montrealMedium">
            {{card.title}}
          </div>
          <div class="flex flex-col gap-8 mt-12">
            <div *ngFor="let list of card.list" class="text-white text-desktopParagrapheXL font-montrealBook whitespace-pre-wrap pb-8 border-b border-grey flex last:border-b-0">
              <div class="h-[25.5px] !w-[25.5px] basis-[25.5] grow-0 shrink-0 bg-[#ffffff1a] rounded-full flex justify-center items-center">
                <img src="assets/img/check.svg">
              </div>
              <div class="ml-4">
                {{list.title}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
