import { Component, ElementRef, Input, ViewChild, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-small-pic',
  templateUrl: './small-pic.component.html',
  styleUrls: ['./small-pic.component.scss']
})
export class SmallPicComponent {
  @Input() data: any;
  @Input() page: any;
  @Input() id: any;

  @ViewChild('scrollableContainer')
  scrollableContainer!: ElementRef;

  @ViewChild('scrollableCards')
  scrollableCards!: ElementRef;

  position = 0

  private isBrowser: boolean;

  constructor( @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit() {

    // Don't calculate browser width under SSR
    if ( ! this.isBrowser ) return;

    let browser_width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (browser_width >= 768) {
      let container: HTMLElement = this.scrollableContainer.nativeElement as HTMLElement;
      let cards: HTMLElement = this.scrollableCards.nativeElement as HTMLElement;
      container.addEventListener('wheel', (event) => {
        let scrollAmount = event.deltaY
        if ((scrollAmount > 0 && this.position <= cards.scrollWidth) || (scrollAmount < 0 && this.position >= 0)) {
          this.position += scrollAmount
          event.preventDefault();
          cards.scrollBy({ left: scrollAmount });
        }
      });
      let mr = (10.0 - ((browser_width * 1.0 - cards.offsetWidth * 1.0) / 2.0)).toString() + "px"
      cards.style.marginRight = mr
      cards.style.marginLeft = mr
    }
  }
}
