import { Component, Input } from '@angular/core';
import { ApiService } from 'src/app/core/api.service';
import { SnackBarService } from 'src/app/core/snackBar.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() data: any;
  @Input() page: any;
  @Input() id: any;

  email_input: string = ''

  constructor(private snackBarService: SnackBarService, private api: ApiService) { }

  sendEmail() {
    this.api.registerContact({ email: this.email_input }).subscribe({
      next: (data) => {
        this.snackBarService.openSnackBar('Email received. Thank you');
      },
      error: (e) => {
        this.snackBarService.openSnackBar(`An error (${e.status}) has ocurred while sending your email, please try again later.`);
      }
    })
  }
}
