import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/api.service';
import { BackgroundImageComponent } from '../background-image/background-image.component';
import { BrandsComponent } from '../brands/brands.component';
import { ButtonCenterComponent } from '../button-center/button-center.component';
import { ButtonComponent } from '../button/button.component';
import { ContentCenterComponent } from '../content-center/content-center.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { ListComponent } from '../list/list.component';
import { SampleComponent } from '../sample/sample.component';
import { SmallPicComponent } from '../small-pic/small-pic.component';
import { SquareComponent } from '../square/square.component';
import { TopLinksComponent } from '../top-links/top-links.component';
import { TrioCenterComponent } from '../trio-center/trio-center.component';
import { SeoComponent } from '../seo/seo.component';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @ViewChild("loader", { read: ViewContainerRef }) loader?: ViewContainerRef;
  componentsMap: Record<string, any> = {
    "sample": SampleComponent,
    "header.top-links": TopLinksComponent,
    "header.header": HeaderComponent,
    "background-image.background-image": BackgroundImageComponent,
    "brands.brands": BrandsComponent,
    "button.button": ButtonComponent,
    "button-center.button-center": ButtonCenterComponent,
    "content-center.content-center": ContentCenterComponent,
    "footer.footer": FooterComponent,
    "list.list": ListComponent,
    "small-pic.small-pic": SmallPicComponent,
    "square.square": SquareComponent,
    "trio-center.trio-center": TrioCenterComponent,
    "seo.seo": SeoComponent,
  }

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
  ) { }

  ngOnInit() {
    const page = this.route.snapshot.paramMap.get('id') || "home";
    this.api.getPage(page!).subscribe(
      pageData => {
        pageData.components.forEach((component: any) => {
          if (this.componentsMap[component.__component]) {
            let componentRef = this.loader?.createComponent(this.componentsMap[component.__component]);
            componentRef?.setInput("data", component);
            componentRef?.setInput("page", page);
            componentRef?.setInput("id", component.__component);
          }
        });
      }
    );
  }

  example(): void {
    const pageData = {
      components: [
        { type: "sample", data: { title: "This is a title" } }
      ]
    }
    pageData.components.forEach((component: any) => {
      let componentRef = this.loader?.createComponent(this.componentsMap[component.type]);
      componentRef?.setInput("data", component.data);
    });
  }

}