import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private defaultTimeOut = 2000
  private defaultTimeOutBetweenMsgs = 500

  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(message: string, timeOut: number | undefined = this.defaultTimeOut) {
    this.snackBar.open(message, '', {
      duration: timeOut,
    });
  }

  openSnackBars(messages: string[], timeOut: number | undefined = this.defaultTimeOut) {
    messages.forEach((message: string, index) => {
      setTimeout(() => {
        this.openSnackBar(message, timeOut)
      }, index * (timeOut + this.defaultTimeOutBetweenMsgs));
    })
  }
}
