<div *ngIf="page == 'home'" class="px-5 w-full flex justify-center pt-12 md:pb-12 bg-purple-secondary">
  <div id={{id}} class="justify-between flex row-auto w-full overflow-x-scroll md:overflow-x-hidden max-w-screen lg:max-w-[1360px] opacity-[0.4] gap-9 lg:gap-0 scrollbar-hide">
    <img *ngFor="let logo of data.logo_brand" src="{{logo.logo_brand.url}} " />
  </div>
</div>
<div *ngIf="page == 'skinlabs'" class="px-5 lg:px-0 w-full flex justify-center pt-[70px] pb-12 md:pb-[120px] bg-grey-dark">
  <div id={{id}} class="justify-between flex row-auto w-full overflow-x-scroll md:overflow-x-hidden max-w-screen lg:max-w-[1360px] opacity-[0.4] gap-9 lg:gap-0 scrollbar-hide">
    <img *ngFor="let logo of data.logo_brand" src="{{logo.logo_brand.url}} " />
  </div>
</div>
