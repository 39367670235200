import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-center',
  templateUrl: './button-center.component.html',
  styleUrls: ['./button-center.component.scss']
})
export class ButtonCenterComponent {
  @Input() data: any;
  @Input() page: any;
  @Input() id: any;
}
