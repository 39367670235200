import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-links',
  templateUrl: './top-links.component.html',
  styleUrls: ['./top-links.component.scss']
})
export class TopLinksComponent {
  @Input() data: any;
  @Input() page: any;
  @Input() id: any;
}
