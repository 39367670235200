import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-trio-center',
  templateUrl: './trio-center.component.html',
  styleUrls: ['./trio-center.component.scss']
})
export class TrioCenterComponent {
  @Input() data: any;
  @Input() page: any;
  @Input() id: any;
}
