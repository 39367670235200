<div id={{id}} class="flex w-full justify-center mb-12 md:mb-32">
  <div class="w-full max-w-[1360px]">
    <h2 class="px-5 lg:px-0 text-mobileH2 md:text-desktopH2 font-morganite text-center uppercase mb-12 md:mb-32 max-w-[1098px] mx-auto">{{data.title}}</h2>
    <div class="bg-black md:rounded-[20px] flex justify-center relative overflow-hidden">
      <img *ngIf="!data.image.mime.startsWith('video')" class="" src="{{data.image.url}}" />      
      <video *ngIf="data.image.mime.startsWith('video')" class="w-full" autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="{{data.image.url}}" type="video/mp4">
      </video>
      <a *ngIf="data.button && data.button.button_text" class="hidden md:block">
        <div class="absolute bottom-[30px] left-[30px] bg-grey-dark py-6 px-5 text-white text-desktopParagrapheXL font-montrealBook flex">
          {{data.button.button_text}}

          <img *ngIf="data.button.logo" class="ml-5" src="{{data.button.logo.url}}">
        </div>
      </a>
    </div>
    <div *ngIf="data.button && data.button.button_text" class="block md:hidden px-5 lg:px-0 pt-5 max-w-[200px] mx-auto text-mobilebodyLight font-montreal text-center">
      {{data.button.button_text}}
    </div>
  </div>
</div>
