import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { BackgroundImageComponent } from './background-image/background-image.component';
import { BrandsComponent } from './brands/brands.component';
import { ButtonCenterComponent } from './button-center/button-center.component';
import { ButtonComponent } from './button/button.component';
import { ContentCenterComponent } from './content-center/content-center.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ListComponent } from './list/list.component';
import { LoaderComponent } from './loader/loader.component';
import { SampleComponent } from './sample/sample.component';
import { SmallPicComponent } from './small-pic/small-pic.component';
import { SquareComponent } from './square/square.component';
import { TopLinksComponent } from './top-links/top-links.component';
import { TrioCenterComponent } from './trio-center/trio-center.component';
import { WebRoutingModule } from './web-routing.module';

@NgModule({
  declarations: [
    LoaderComponent,
    SampleComponent,
    TopLinksComponent,
    BackgroundImageComponent,
    BrandsComponent,
    ButtonComponent,
    ButtonCenterComponent,
    ContentCenterComponent,
    FooterComponent,
    HeaderComponent,
    ListComponent,
    SmallPicComponent,
    SquareComponent,
    TrioCenterComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    WebRoutingModule
  ],
})
export class WebModule { }
