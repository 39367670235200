<div id="{{id}}" *ngIf="page == 'home'" class="relative md:min-h-hero">
  <div #swipableHome [ngClass]="{'animation-forwards animate-fade-in-down':updating}" class="absolute w-full h-full animate-fade-in-down w-100 bg-no-repeat bg-contain sm:bg-cover bg-right-bottom sm:bg-right-top rounded-b-[40px] md:rounded-b-[70px]" style="background-image: url('{{data.trio_images[selectedIndex].image.url}}'), linear-gradient(90deg, {{data.trio_images[selectedIndex].color_1}} 22.88%, {{data.trio_images[selectedIndex].color_2}} 100%);"></div>
  <div class="hidden md:block z-[2] absolute mx-auto right-0 h-full md:w-1/2 lg:w-1/3 md:top-1/2 lg:top-1/3 left-1/2">
    <ng-container *ngFor="let i of [0,1,2]">
      <div (click)="switchPhoto(i)" [ngClass]="getCardClass(i)" class="hero-card overflow-hidden animation-forwards transition-default absolute aspect-square border-white rounded-md cursor-pointer animate-none hover:animate-bounce hover:shadow-lg transition-all duration-150">
        <div [ngClass]="{'h-[75%]':selectedIndex==i,'h-[100%]':selectedIndex!=i}" class="transition-default" style="object-fit:cover; transform-origin:right top; scale:1.5; background-position:top right; background-repeat: no-repeat;background-size: cover;background-image: url('{{data.trio_images[i].image.url}}'), linear-gradient(360deg, {{data.trio_images[i].color_1}} 22.88%, {{data.trio_images[i].color_2}} 100%);"></div>
        <div [ngClass]="{'h-[25%] text-4':selectedIndex==i,'h-[0%] text-[0px]':selectedIndex!=i}" class="transition-default relative flex leading-none bg-white justify-center">
          <div class="relative self-center text-center align-middle uppercase">{{data.trio_images[i].text}}</div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="relative z-1 justify-between items-center flex row-auto px-5 xl:px-0 pt-14 md:pt-8 mb-32 mx-auto max-w-[1360px]">
    <img src="{{data.logo.url}}" />
    <div class="hidden md:flex items-center gap-8">
      <div *ngFor="let link of data.top_links; index as i;">
        <a routerLink="/home" fragment="{{link.link}}" class="primary text-base font-montreal-book">
          {{link.text_link}}
        </a>
      </div>
    </div>
    <a href="{{data.link}}">
      <button [attr.data-hover]="data.text_link" class="primary">
        <div>{{data.text_link}}</div>
      </button>
    </a>
  </div>
  <div class="flex justify-center w-full px-5 md:mx-0">
    <div class="relative z-1 w-full max-w-[1360px]">
      <div class="pb-10 text-desktopOverline font-montrealMedium uppercase">{{data.text}}</div>
      <h1 class="text-mobileH1 md:text-desktopH1 font-morganite uppercase max-w-[720px] mb-16 md:mb-28">{{data.title}}</h1>
      <a class="hidden md:block" href="{{data.button_2[0].button_external_link}}">
        <button [attr.data-hover]="data.button_2[0].button_text" class="primary mb-32">
          <div>{{data.button_2[0].button_text}}</div>
        </button>
      </a>

      <div class="block md:hidden mb-14 w-1/3">
        <ng-container *ngFor="let image of data.trio_images;let i = index">
          <div *ngIf="selectedIndex==i" class="mb-4">{{image.text}}</div>
        </ng-container>
        <div class="w-full h-1 bg-[#979eca] opacity-100">
          <div [ngClass]="getSlidePosition()" class="transition-default relative w-1/3 h-full bg-[#16181A] opacity-100"></div>
        </div>
      </div>


      <div class="mb-14 md:flex items-center">
        <a class="text-base font-montrealMedium flex items-end primary mr-3" href="{{data.button[0].button_external_link}}">{{data.button[0].button_text}}</a>
        <div class="flex mt-5 md:mt-0">
          <img *ngFor="let image of data.images" src="{{image.image.url}}" class="first:ml-0 -ml-[15px] h-[28px]" />
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="page == 'skinlabs'" class="bg-grey-dark">
  <div class="block md:hidden w-100 bg-no-repeat bg-cover md:bg-contain bg-right-bottom rounded-b-[40px] bg-[#000000] max-h-screen overflow-hidden">
    <div class="items-center flex row-auto px-8 pt-8 mb-8 md:mb-52">
      <img src="{{data.logo.url}} " class="mr-10" />
      <div class="flex justify-between w-full items-center">
        <div *ngFor="let link of data.top_links; index as i;">
          <a *ngIf="i == 0" class="hidden md:block" href="{{link.link}}">
            <div class="text-grey text-lg font-montrealBook flex">
              <img src="assets/img/arrow-left.svg" class="mr-2">
              {{link.text_link}}
            </div>
          </a>
          <a *ngIf="i != 0" href="{{link.link}}">
            <div class="bg-grey-dark p-4 text-white rounded-[10px] text-desktopParagraphe font-montrealBook">
              {{link.text_link}}
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="flex justify-center w-full relative">
      <svg width="1381" height="668" viewBox="0 0 1381 668" fill="none" xmlns="http://www.w3.org/2000/svg" class="absolute z-10 bottom-0 right-0 left-0 pointer-events-none">
        <g opacity="0.3" filter="url(#filter0_f_518_1084)">
        <path d="M574.477 405.806C402.084 511.744 135.582 549.264 23.8799 554.783L33.7612 606.729C581.368 667.727 610.183 449.891 878.333 320.724C1092.85 217.391 1285.79 217.164 1355.44 229.968L1348.13 191.541C1021.34 142.366 789.968 273.384 574.477 405.806Z" fill="#4A7BE0"/>
        </g>
        <defs>
        <filter id="filter0_f_518_1084" x="-156.12" y="0.968506" width="1691.56" height="796.31" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="90" result="effect1_foregroundBlur_518_1084"/>
        </filter>
        </defs>
      </svg>
      <video class="min-h-hero mt-14" autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="{{data.images[0].image.url}}" type="video/mp4">
      </video>
      <div class="absolute top-auto sm:top-0 left-0 px-5 md:px-0 w-full max-w-[1098px] bg-no-repeat bg-contain bg-right-top z-[5]">
        <a class="block md:hidden mb-9" href="{{data.top_links[0].link}}">
          <div class="text-grey text-lg font-montrealBook flex">
            <img src="assets/img/arrow-left.svg" class="mr-2">
            {{data.top_links[0].text_link}}
          </div>
        </a>
        <h1 class="text-mobileH1 md:text-desktopH1 text-white font-morganite uppercase md:max-w-[767px] mb-5">{{data.title}}</h1>
        <a href="{{data.button[0].button_external_link}}">
          <button class="hover:bg-blue hover:text-blue-text text-white bg-[#000000] border border-white p-4 rounded-[10px] font-montrealMedium text-desktopParagraphe flex gap-2.5">
            {{data.button[0].button_text}}
            <img src="{{data.button[0].logo.url}}">
          </button>
        </a>
      </div>
    </div>
    <div id="here" class="hidden">{{data|json}}</div>
    <div class="hidden w-full h-72 -ml-[20%] bg-no-repeat bg-cover md:bg-contain bg-center rounded-b-[40px]" style="background-image:url('{{data.images[0].image.url}}'), linear-gradient(90deg, #000000 22.88%, #000000 100%);">

    </div>
  </div>
  <div class="relative hidden md:grid justify-items-end overflow-hidden w-100 bg-no-repeat bg-cover md:bg-contain bg-right-bottom rounded-b-[40px] md:rounded-b-[70px] sm:min-h-[800px] max-h-screen" style="background-image:url(), linear-gradient(90deg, #000000 22.88%, #000000 100%);">
    <svg width="1381" height="668" viewBox="0 0 1381 668" fill="none" xmlns="http://www.w3.org/2000/svg" class="absolute z-10 bottom-0 right-0 left-0 pointer-events-none">
      <g opacity="0.3" filter="url(#filter0_f_518_1084)">
      <path d="M574.477 405.806C402.084 511.744 135.582 549.264 23.8799 554.783L33.7612 606.729C581.368 667.727 610.183 449.891 878.333 320.724C1092.85 217.391 1285.79 217.164 1355.44 229.968L1348.13 191.541C1021.34 142.366 789.968 273.384 574.477 405.806Z" fill="#4A7BE0"/>
      </g>
      <defs>
      <filter id="filter0_f_518_1084" x="-156.12" y="0.968506" width="1691.56" height="796.31" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="90" result="effect1_foregroundBlur_518_1084"/>
      </filter>
      </defs>
    </svg>
      
    <video class="scale-150 pr-32 xl:pr-52 pt-12 min-h-[850px]" autoplay loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true" style="height: calc(100vh - 200px);">
      <source src="{{data.images[0].image.url}}" type="video/mp4">
    </video>
    <div class="absolute top-0 left-0 z-[5] w-full rounded-b-[40px] md:rounded-b-[70px]">
      <div class="items-center flex row-auto px-8 pt-8 mb-8 md:mb-52">
        <img src="{{data.logo.url}} " class="mr-10" />
        <div class="flex justify-between w-full items-center">
          <div *ngFor="let link of data.top_links; index as i;" class="mt-1">
            <a *ngIf="i == 0" href="{{link.link}}">
              <div class="text-grey text-lg font-montrealBook flex">
                <img src="assets/img/arrow-left.svg" class="mr-2">
                {{link.text_link}}
              </div>
            </a>
            <a *ngIf="i != 0" href="{{link.link}}">
              <button class="primary" [attr.data-hover]="link.text_link">
                <div>
                  {{link.text_link}}
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="flex justify-center w-full pb-40">
        <div class="w-full max-w-[1098px] bg-no-repeat bg-contain bg-right-top ">
          <h1 class="text-desktopH1 text-white font-morganite uppercase max-w-[767px] mb-5">{{data.title}}</h1>
          <a href="{{data.button[0].button_external_link}}">
            <button class="primary text-black bg-white border border-white w-full md:w-auto md:mb-32 flex gap-2.5 justify-center" [attr.data-hover]="data.button[0].button_text">
              <div class="flex gap-2">
                {{data.button[0].button_text}}
                <img src="{{data.button[0].logo.url}}">
              </div>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
