<div id={{id}} *ngIf="page == 'home'">
  <div class="lg:hidden w-full flex justify-center rounded-t-[40px] bg-grey-dark bg-contain bg-right-top bg-no-repeat" style="background-image: url('assets/img/blur_footer.svg');">
    <div class="w-full">
      <div class="bg-contain bg-right-top bg-no-repeat pt-12 flex justify-center">
        <div class="px-5 w-full max-w-[1098px]">
          <div class="flex flex-col gap-14 pb-12 max-w-[60%] mx-auto text-center">
            <div class=" basis-[20%]">
              <div class="pb-6 text-white">
                {{data.top_text[0].title}}
              </div>
              <div class="text-grey-secondary text-desktopParagraphe font-montreal">
                {{data.top_text[0].description}}
              </div>
            </div>
            <div class=" basis-[20%]">
              <div class="pb-6 text-white">
                {{data.top_text[1].title}}
              </div>
              <div class="text-grey-secondary text-desktopParagraphe font-montreal">
                {{data.top_text[1].description}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center mb-16" style="display: none;">
        <div class="w-full">
          <div class="flex flex-col justify-center">
            <div class="max-w-[60%] mx-auto text-center">
              <div class="flex flex-col">
                <div class="text-grey text-mobileOverline font-montrealMedium mb-5">
                  {{data.text}}
                </div>
                <h2 class="text-desktopH2 font-morganite text-white uppercase">
                  {{data.title}}
                </h2>
                <div class="text-mobileParagraphe font-montreal text-grey-secondary">
                  {{data.description}}
                </div>
              </div>
            </div>
            <div class="w-full px-5 mt-14">
              <form>
                <div class="relative z-0 mb-4">
                  <input type="email" class="block py-5 px-0 w-full bg-transparent border-0 border-b border-white appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer text-white text-base font-montrealMedium" placeholder=" " required />
                  <label for="email" class="absolute text-white text-base font-montrealMedium duration-300 transform -translate-y-4 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">{{data.mail_placeholder}}</label>
                  <button class="absolute right-0 top-[30px]">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1164_263)">
                        <path d="M18.3334 1.66675L9.16675 10.8334" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.3334 1.66675L12.5001 18.3334L9.16675 10.8334L1.66675 7.50008L18.3334 1.66675Z" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1164_263">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <img class="float-right" src="{{data.background_mobile.url}}">
      </div>
      <div class="w-full flex justify-center pb-8">
        <div class="w-full px-5">
          <div class="">
            <div class="mb-10">
              <img src="{{data.logo.url}}" />
            </div>
            <div class="flex justify-between">
              <a *ngFor="let link of data.link; index as i;" href="{{link.link}}" class=" text-mobileParagraphe font-montrealBook text-white">{{link.text}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full hidden lg:flex justify-center rounded-t-[70px] bg-grey-dark bg-contain bg-right-top bg-no-repeat" style="background-image: url('assets/img/blur_footer.svg');">
    <div class="w-full">
      <div class="bg-contain bg-right-top bg-no-repeat pt-20 flex justify-center" style="background-image: url('{{data.background.url}}');">
        <div class="w-full max-w-[1098px]">
          <div class="flex flex-row gap-14 pb-32">
            <div class=" basis-[20%]">
              <div class="pb-6 text-white">
                {{data.top_text[0].title}}
              </div>
              <div class="text-grey-secondary text-desktopParagraphe font-montreal">
                {{data.top_text[0].description}}
              </div>
            </div>
            <div class=" basis-[20%]">
              <div class="pb-6 text-white">
                {{data.top_text[1].title}}
              </div>
              <div class="text-grey-secondary text-desktopParagraphe font-montreal">
                {{data.top_text[1].description}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center mb-16">
        <div class="w-full max-w-[1098px]">
          <div class="flex flex-row justify-between items-end">
            <div class="basis-3/12">
              <div class="flex flex-col">
                <div class="text-grey text-desktopOverline font-montrealMedium mb-5">
                  {{data.text}}
                </div>
                <h2 class="text-desktopH2 font-morganite text-white uppercase">
                  {{data.title}}
                </h2>
                <div class="text-desktopParagraphe font-montreal text-grey-secondary">
                  {{data.description}}
                </div>
              </div>
            </div>
            <div class="basis-7/12">
              <form>
                <div class="relative z-0 mb-4 w-full">
                  <input type="email" [ngModelOptions]="{standalone: true}" [(ngModel)]=" this.email_input" class="block py-5 px-0 w-full bg-transparent border-0 border-b border-white appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer text-white text-base font-montrealMedium" placeholder=" " required />
                  <label for="email" class="absolute text-white text-base font-montrealMedium duration-300 transform -translate-y-4 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">{{data.mail_placeholder}}</label>
                  <button (keyup.enter)="sendEmail()" (click)="sendEmail()" class="absolute right-0 top-[30px]">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1164_263)">
                        <path d="M18.3334 1.66675L9.16675 10.8334" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.3334 1.66675L12.5001 18.3334L9.16675 10.8334L1.66675 7.50008L18.3334 1.66675Z" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1164_263">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center pb-20">
        <div class="w-full max-w-[1098px]">
          <div class="flex justify-between">
            <div>
              <img src="{{data.logo.url}}" />
            </div>
            <div class="basis-7/12 flex justify-between">
              <a *ngFor="let link of data.link; index as i;" href="{{link.link}}" class="footer text-desktopParagraphe font-montrealBook text-white last:ml-auto mr-5 last:mr-0">{{link.text}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="page == 'skinlabs'" class="bg-grey-dark">
  <div class="lg:hidden w-full flex justify-center rounded-t-[40px] bg-grey-dark bg-contain bg-right-top bg-no-repeat" style="background-image: url('assets/img/blur_footer.svg');">
    <div class="w-full">
      <div class="bg-contain bg-right-top bg-no-repeat pt-12 flex justify-center">
        <div class="px-5 w-full max-w-[1098px]">
          <div class="flex flex-col gap-14 pb-12 max-w-[60%] mx-auto text-center">
            <div class=" basis-[20%]">
              <div class="pb-6 text-white">
                {{data.top_text[0].title}}
              </div>
              <div class="text-grey-secondary text-desktopParagraphe font-montreal">
                {{data.top_text[0].description}}
              </div>
            </div>
            <div class=" basis-[20%]">
              <div class="pb-6 text-white">
                {{data.top_text[1].title}}
              </div>
              <div class="text-grey-secondary text-desktopParagraphe font-montreal">
                {{data.top_text[1].description}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center mb-16" style="display: none;">
        <div class="w-full">
          <div class="flex flex-col justify-center">
            <div class="max-w-[60%] mx-auto text-center">
              <div class="flex flex-col">
                <div class="text-grey text-mobileOverline font-montrealMedium mb-5">
                  {{data.text}}
                </div>
                <h2 class="text-desktopH2 font-morganite text-white uppercase">
                  {{data.title}}
                </h2>
                <div class="text-mobileParagraphe font-montreal text-grey-secondary">
                  {{data.description}}
                </div>
              </div>
            </div>
            <div class="w-full px-5 mt-14">
              <form>
                <div class="relative z-0 mb-4">
                  <input type="email" class="block py-5 px-0 w-full bg-transparent border-0 border-b border-white appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer text-white text-base font-montrealMedium" placeholder=" " required />
                  <label for="email" class="absolute text-white text-base font-montrealMedium duration-300 transform -translate-y-4 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">{{data.mail_placeholder}}</label>
                  <button class="absolute right-0 top-[30px]">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1164_263)">
                        <path d="M18.3334 1.66675L9.16675 10.8334" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.3334 1.66675L12.5001 18.3334L9.16675 10.8334L1.66675 7.50008L18.3334 1.66675Z" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1164_263">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <img class="float-right" src="{{data.background_mobile.url}}">
      </div>
      <div class="w-full flex justify-center pb-8">
        <div class="w-full px-5">
          <div class="">
            <div class="mb-10">
              <img src="{{data.logo.url}}" />
            </div>
            <div class="flex justify-between">
              <a *ngFor="let link of data.link; index as i;" href="{{link.link}}" class=" text-mobileParagraphe font-montrealBook text-white">{{link.text}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full hidden lg:flex justify-center rounded-t-[70px] bg-grey-dark bg-contain bg-right-top bg-no-repeat" style="background-image: url('assets/img/blur_footer.svg');">
    <div class="w-full">
      <div class="bg-contain bg-right-top bg-no-repeat pt-20 flex justify-center" style="background-image: url('{{data.background.url}}');">
        <div class="w-full max-w-[1098px]">
          <div class="flex flex-row gap-14 pb-32">
            <div class=" basis-[20%]">
              <div class="pb-6 text-white">
                {{data.top_text[0].title}}
              </div>
              <div class="text-grey-secondary text-desktopParagraphe font-montreal">
                {{data.top_text[0].description}}
              </div>
            </div>
            <div class=" basis-[20%]">
              <div class="pb-6 text-white">
                {{data.top_text[1].title}}
              </div>
              <div class="text-grey-secondary text-desktopParagraphe font-montreal">
                {{data.top_text[1].description}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center mb-16" style="display: none;">
        <div class="w-full max-w-[1098px]">
          <div class="flex flex-row justify-between items-end">
            <div class="basis-3/12">
              <div class="flex flex-col">
                <div class="text-grey text-desktopOverline font-montrealMedium mb-5">
                  {{data.text}}
                </div>
                <h2 class="text-desktopH2 font-morganite text-white uppercase">
                  {{data.title}}
                </h2>
                <div class="text-desktopParagraphe font-montreal text-grey-secondary">
                  {{data.description}}
                </div>
              </div>
            </div>
            <div class="basis-7/12">
              <form>
                <div class="relative z-0 mb-4 w-full">
                  <input type="email" class="block py-5 px-0 w-full bg-transparent border-0 border-b border-white appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer text-white text-base font-montrealMedium" placeholder=" " required />
                  <label for="email" class="absolute text-white text-base font-montrealMedium duration-300 transform -translate-y-4 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">{{data.mail_placeholder}}</label>
                  <button class="absolute right-0 top-[30px]">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1164_263)">
                        <path d="M18.3334 1.66675L9.16675 10.8334" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.3334 1.66675L12.5001 18.3334L9.16675 10.8334L1.66675 7.50008L18.3334 1.66675Z" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1164_263">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center pb-20">
        <div class="w-full max-w-[1098px]">
          <div class="flex justify-between">
            <div>
              <img src="{{data.logo.url}}" />
            </div>
            <div class="basis-7/12 flex justify-between">
              <a *ngFor="let link of data.link; index as i;" href="{{link.link}}" class="footer text-desktopParagraphe font-montrealBook text-white last:ml-auto mr-5 last:mr-0">{{link.text}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
