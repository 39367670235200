import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-background-image',
  templateUrl: './background-image.component.html',
  styleUrls: ['./background-image.component.scss']
})
export class BackgroundImageComponent {
  @Input() data: any;
  @Input() page: any;
  @Input() id: any;
}
