<div id={{id}} class="flex justify-center bg-no-repeat bg-cover bg-center px-5 py-12 md:py-24"
  style="background-image: url('assets/img/home_blur_background.svg');">
  <div class="w-full max-w-[1360px] flex flex-col md:flex-row">
    <div class="md:basis-4/12">
      <div class="text-mobileOverline md:text-desktopOverline font-montrealMedium uppercase">{{data.text}}</div>
      <h2 class="!text-desktopH2 !font-morganite my-5 uppercase">{{data.title}}</h2>
      <a href="{{data.button.button_external_link}}" class="no-underline">
        <button [attr.data-hover]="data.button.button_text" class="primary hidden sm:block">
          <div>            
              {{data.button.button_text}}            
          </div>
        </button>
      </a>
    </div>
    <div class="md:basis-8/12">
      <div class="md:grid md:grid-cols-2 md:gap-4">
        <div *ngFor="let list of data.details" class="flex items-start md:block gap-5 md:gap-0 border-b border-[#0000001A] md:border-none py-4 md:py-0">
          <img style="width: 16px;" src="{{list.logo.url}}" />
          <div>
            <h6 class="md:pt-5 pb-2 text-mobileH6 md:text-desktopH6 font-montrealMedium">{{list.list_title}}</h6>
            <div class="text-base font-montreal">{{list.list_description}} </div>
          </div>
        </div>
      </div>
      <a href="{{data.button.button_external_link}}" class="no-underline">
        <button class="sm:hidden primary w-full mt-4" [attr.data-hover]="data.button.button_text">
          <div>          
              {{data.button.button_text}}          
          </div>
        </button>
      </a>
    </div>
  </div>
</div>
